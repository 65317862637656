import * as pdfjsLib from 'pdfjs-dist/webpack';
import { PDFDocument, degrees } from 'pdf-lib';

export default class PdfFile {

    pageCount = null; // private
    pdf = null; // private
    valid = false;
    original = null;
    uint8array = null;
    previewUrl = '';
    id = 0;
    pages = [];

    async fromFile(file) {
        this.pageCount = null; // private
        this.pdf = null; // private
        this.valid = false;
        this.uint8array = null;
        this.previewUrl = '';
        this.pages = [];

        this.original = file;
        
        if(!this.isValid()) {
            this.original = null;
            
            return;
        };

        this.uint8array = new Uint8Array(await file.arrayBuffer());
    }

    isValid() {
        return this.original.type === 'application/pdf';
    }

    async getPdfDoc() {
        if(this.pdf === null) {
            this.pdf = await pdfjsLib.getDocument(this.uint8array).promise;
        }

        return this.pdf;
    }

    async getPageCount() {
        if(this.pageCount === null) {
            const pdf = await this.getPdfDoc();
            
            this.pageCount = pdf.numPages;
        }

        return this.pageCount;
    }

    async getPreviewUrl() {
        const url = await this.getPagePreviewUrl(1, .5);

        this.previewUrl = url;
    }

    async getPagePreviewUrl(pageId, scale) {
        const pdf = await this.getPdfDoc();
        const page = await pdf.getPage(pageId);
        const viewport = page.getViewport({scale});
        const canvas = window.document.createElement('canvas');

        canvas.classList.add('page-render-canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        window.document.body.appendChild(canvas);

        const canvasContext = canvas.getContext('2d');

        await page.render({canvasContext, viewport}).promise;
        
        const url = await new Promise(resolve => {
            canvas.toBlob(blob => {
                resolve(URL.createObjectURL(blob));
            });
        });

        canvas.remove();

        return url;
    }

    async generatePagesInfo(previewScale) {
        this.pages = [];

        const pageCount = await this.getPageCount();

        for(let pageId = 1; pageId <= pageCount; pageId++) {
            const page = {
                index: pageId - 1,
                number: pageId,
                previewUrl: null,
                /* content: await this.getPageContent(pageId), */
                viewport: null,
            };

            this.pages = [...this.pages, page];
        }

        for(let page of this.pages) {
            page.previewUrl = await this.getPagePreviewUrl(page.number, previewScale);
            page.viewport = await this.getPageViewport(page.number, 1);
            page.content = await this.getPageContent(page.number);
            page.contentStream = await this.getPageContentStream(page.number);
        }
    }

    async getPageContent(pageId) {
        const pdf = await this.getPdfDoc();
        const page = await pdf.getPage(pageId);
        
        return await page.getTextContent({
            normalizeWhitespace: false,
            disableCombineTextItems: true,
            includeMarkedContent: true,
        });
    }

    async getPageContentStream(pageId) {
        const pdf = await this.getPdfDoc();
        const page = await pdf.getPage(pageId);
        
        return await page.streamTextContent();
    }

    async getPageViewport(pageId, scale) {
        const pdf = await this.getPdfDoc();
        const page = await pdf.getPage(pageId);
        return page.getViewport({scale});
    }

    async rotate(direction) {
        const rotation = 90 * direction;
        const rotatedPdf = await PDFDocument.load(this.uint8array);

        for(let page of rotatedPdf.getPages()) {
            console.log({rotation, newRotation: degrees(page.getRotation().angle + rotation)});
            page.setRotation(degrees(page.getRotation().angle + rotation));
        }

        const blob = new Blob([await rotatedPdf.save()], {type: 'application/pdf'});

        await this.fromFile(new File([blob], this.original.name, {type: 'application/pdf'}));
        await this.getPreviewUrl();

        console.log(this.previewUrl);
    }
}