import PdfFileList from './PdfFileList.js';

export default class UploadService {
    
    static async uploadPdfFiles(multiple = false) {
        const input = document.createElement('input');

        input.type = 'file';
        input.multiple = multiple;
        input.accept = '.pdf';
        document.body.appendChild(input);
        input.style = 'display: none';

        return new Promise(resolve => {
            input.addEventListener('change', async () => {
                const pdfFileList = new PdfFileList();

                await pdfFileList.fromFileList(input.files);

                resolve(pdfFileList);
            });

            input.click();
        });
    }
}