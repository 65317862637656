<template>
    <div class="open-file">
        <div class="drop-zone" ref="dropZone" @dragover.prevent="dragOver" @dragleave="dragLeave" @drop.prevent="selectFromDrop">
            <button class="btn-inline import-icon" @click="chooseFile">
                <i class="las la-file-import"></i>
            </button>

            <span>
                <button class="btn-inline" @click="chooseFile">{{ $t('open_file.choose_pdf', multiple ? 2 : 1) }}</button>
                <span>&nbsp;</span>
                <span v-if="supportsDragAndDrop">{{ $t('open_file.or_drag_pdf', multiple ? 2 : 1) }}</span>
            </span>
        </div>

        <input type="file" accept=".pdf" ref="fileInput" @change="selectFromInput" :multiple="multiple">
    </div>
</template>

<script>
import PdfFileList from '@/PdfFileList.js';

export default {
    name: 'open-file',
    props: {
        multiple: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            supportsDragAndDrop: (() => {
                const div = document.createElement('div');
                return 'ondragover' in div && 'ondrop' in div && 'FileReader' in window;
            })(),
        }
    },
    methods: {
        dragOver() {
            this.$refs.dropZone.classList.add('active');
        },
        dragLeave() {
            this.$refs.dropZone.classList.remove('active');
        },
        chooseFile() {
            this.$refs.fileInput.click();
        },
        async selectFromDrop(event) {
            const pdfFileList = new PdfFileList();

            await pdfFileList.fromFileList(event.dataTransfer.files);

            this.$emit('select-files', pdfFileList);
        },
        async selectFromInput() {
            const pdfFileList = new PdfFileList();

            await pdfFileList.fromFileList(this.$refs.fileInput.files);

            this.$emit('select-files', pdfFileList);
        },
    },
}
</script>

<style scoped>
.open-file {
    height: 38.2vh;
}

.drop-zone {
    height: 100%;
    width: 100%;
    border: 6px dashed var(--text-default-color);
    border-radius: .3rem;
    font-size: 1.2rem;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: .75rem 1rem;
    transition: background-color .2s ease;
}

.drop-zone.active {
    background-color: var(--light-color);
    cursor: copy;
}

.import-icon {
    line-height: 1;
    font-size: 6rem;
    margin-bottom: 1.5rem;
}

input[type="file"] {
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
}
</style>