import PdfFile from './PdfFile';

export default class PdfFileList {

    files = [];
    #currentId = 0; // private

    async fromFileList(fileList) {
        this.files = [];

        for(let file of fileList) {
            const pdfFile = new PdfFile();
            await pdfFile.fromFile(file);

            pdfFile.id = this.#currentId++;

            if(pdfFile.isValid()) this.files = [...this.files, pdfFile];
        }
    }

    appendPdfFileList(pdfFileList) {
        for(let pdfFile of pdfFileList.files) {
            pdfFile.id = this.#currentId++;

            if(pdfFile.isValid()) this.files = [...this.files, pdfFile];
        }
    }

    isEmpty() {
        return this.files.length === 0;
    }

    removeItem(id) {
        this.files = this.files.filter(file => file.id !== id);
    }
}